import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import { storyblokEditable } from 'gatsby-source-storyblok';

const SliderGallery = loadable(() => import('../SliderGallery'));
const SalonGallery = loadable(() => import('../Gallery/SalonGallery'));
import { GET_GALLERY_PLACEMENTS_BY_IDENTIFIER } from '../../queries/galleryQueries';

const DynamicUgcGallery = ({ blok }) => {
  const { layout = 'uniform', identifier } = blok;
  const { data, loading } = useQuery(GET_GALLERY_PLACEMENTS_BY_IDENTIFIER, {
    variables: { identifier },
  });

  const placements = data
    ? data.placementsForGalleryByIdentifier
      ? data.placementsForGalleryByIdentifier
      : []
    : [];
  switch (layout) {
    case 'uniform':
      return (
        <SliderGallery
          {...storyblokEditable(blok)}
          {...blok}
          placements={placements}
          loading={loading}
          key={blok._uid}
        />
      );
    default:
      return (
        <SalonGallery
          {...storyblokEditable(blok)}
          {...blok}
          placements={placements}
          loading={loading}
        />
      );
  }
};

DynamicUgcGallery.propTypes = {
  blok: PropTypes.shape({
    title: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['uniform', 'salon']),
    _uid: PropTypes.string,
  }),
};

export default DynamicUgcGallery;
